@import '../../TripVariables.scss';

.map-container {
  z-index: 0;
  width: 100%;
  /* height: calc(100% - #{$mobile-menu-height}); */
  height: 100%;
  /* background-color: rgb(229, 227, 223); */
  overflow: hidden;
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .map-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - #{$desktop-list-width});
    height: calc(100% - #{$desktop-navbar-height});
    /* background-color: blue; */
    transition: width #{$list-transition};
  }

  .w-m-100 {
    width: 100%;
  }
}
