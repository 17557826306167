@import '../../pages/Trip/TripVariables.scss';

.header {
  position: relative;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

  .hcontainer {
    height: $mobile-navbar-height;
    padding: 4px;
    position: relative;

    & > * {
      /* position: absolute; */
      top: 50%;
      transform: translateY(-50%);
    }

    & > button {
      padding: 1rem 0.5rem 1rem !important;
    }

    .day-switch {
      position: absolute;
      display: inline-flex;
      width: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .header {
    .hcontainer {
      height: $desktop-navbar-height;
      padding: 4px;

      .day-switch {
        width: 10rem;
      }
    }
  }
}
