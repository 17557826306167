@import '../../TripVariables.scss';

.stepList {
  text-align: left;
  /* color: green; */

  .switch {
    display: inline-flex;
    align-items: center;
    position: relative;
    float: right;
    margin-right: 1rem;
  }

  .stepListCards {
    /* border: 1px solid red; */
    padding: 0.5rem;
  }
}
/* @media screen and (min-width: $mobile-view-breakpoint) {
  .stepList {
    .stepListCards {
      padding-right: 0;
    }
  }
}
 */
