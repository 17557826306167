@import '../../TripVariables.scss';

$menu-bg-color: #ffffff;
$menu-button-color: #1787c5;

.menu {
  z-index: 1;
  height: $mobile-menu-height;
  width: 100%;
  /* background-color: bisque;
  background-color: #aadaff; */
  background-color: transparent;

  position: fixed;
  bottom: 0;
  left: 0;
  /* overflow: hidden; */
  display: flex;

  box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  /* -webkit-box-shadow: 0px 0px 15px -5px rgba(204, 204, 204, 1);
  -moz-box-shadow: 0px 0px 15px -5px rgba(204, 204, 204, 1);
  box-shadow: 0px 0px 15px -5px rgba(204, 204, 204, 1); */

  .mitem {
    width: 20%;
    background-color: $menu-bg-color;

    background-size: auto calc(70%);
    background-position: center center;
    background-repeat: no-repeat;

    &.eat {
      background-image: url('../../../../assets/img/menu/eat-drink.png');
    }
    &.attraction {
      background-image: url('../../../../assets/img/menu/attractions.png');
    }
    &.favorites {
      background-image: url('../../../../assets/img/menu/favorites.png');
    }
    &.search {
      background-image: url('../../../../assets/img/menu/search.png');
    }
  }

  .shape {
    margin: 0 auto;
    /* WebKit browsers, old syntax */
    background: -webkit-radial-gradient(50% 0, circle, transparent 30%, $menu-bg-color 30%);
    /* IE10, current versions of Firefox and Opera */
    background: radial-gradient(circle at 50% 0, transparent 50%, $menu-bg-color 50%);
  }

  .circle {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0;
    left: 50%;
    border-radius: 50%;

    height: $mobile-menu-height;
    width: $mobile-menu-height;
    /* height: #{$mobile-menu-height + 5px};
    width: #{$mobile-menu-height + 5px}; */

    background-color: $menu-button-color;
    box-shadow: 0 0 5px 6px rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

    display: flex;
    overflow: hidden;

    &.shadow {
      /* box-shadow: 0px 0px 15px 0px #aaaaaa; */
      box-shadow: 0px 0px 10px 5px #cccccc;
    }

    input {
      border: none;
      background-color: transparent;
      color: white;
      width: 100%;
      height: auto;

      background-image: url('../../../../assets/img/menu/menu-button.png');
      background-size: calc(100% - 20px);
      background-position: center;
      padding: 10px;
      background-repeat: no-repeat;

      cursor: pointer;
    }
  }
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .menu {
    display: none;
    /* height: $desktop-menu-height;
    background-color: #484848;
    bottom: auto;
    top: 0;
    justify-content: space-between;

    .mitem {
      width: auto;
      flex: 1;
    }
    .mitem.shape {
      display: none;
    }
    .mitem.favorites {
      order: 1;
    }
    .mitem.eat {
      order: 2;
    }
    .mitem.attraction {
      order: 3;
    }
    .mitem.favorites {
      order: 5;
    }
    .mitem.search {
      order: 4;
    }

    .circle {
      order: 1;
      position: static;
      transform: none;
      border-radius: 0;

      height: $desktop-menu-height;
      width: $desktop-menu-height;

      background-color: $menu-button-color;

      overflow: hidden;

      input {
        border: none;
        background-color: transparent;
        color: white;
        width: 100%;
        height: auto;

        background-image: url('../../../../assets/img/menu/menu-button.png');
        background-size: calc(100% - 20px);
        background-position: center;
        padding: 10px;
        background-repeat: no-repeat;
      }
    } */
  }
}
