.App {
  width: 100% !important;
  height: 100% !important;
  overflow-y: auto;
}

.full-center {
  position: relative;
  margin: 0 auto;
  top: 50vh;
  transform: translateY(-50%);
  max-width: 450px;
  min-width: 300px;
  padding: 1rem;
}

.login-form-wrapper {
  position: relative;
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 10% 5%;
  width: 100%;
  height: 100%;
}
