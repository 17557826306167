*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* font-size: 24px; */
}

body {
  /* overflow: hidden; */
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.root {
  /* overflow: hidden; */
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  height: 100%;
  width: 100%;

  background: #f5f5f5;
  margin: 0;
  padding: 0;

  color: #383636;
  background-color: white;

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* .body { */
/* Sending body at the bottom of the stack */
/*   z-index: 1;
} */

/* .root { */
/* Making the wrapper stack above the body */
/*   z-index: 2;
} */

@import './scrollable.css';
