@import '../../TripVariables.scss';

.focusContainer {
  z-index: 6;
  /* height: $mobile-list-height; */
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  /* bottom: $mobile-menu-height; */
  bottom: 0px;
  /* padding-bottom: #{$mobile-menu-height}; */
  left: 0;
  transition: height #{$list-transition};
  /* overflow: hidden; */

  &.focusContainerOpen {
    height: 100%; /* calc(100% - #{$mobile-menu-height});  $mobile-list-height; */
    /* overflow: auto; */
  }

  &.focusContainerClose {
    height: 0;
    /* padding-bottom: 0; */
    /* overflow: hidden; */
  }
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .focusContainer {
    height: calc(100% - #{$desktop-navbar-height});
    width: 0;
    background-color: #ffffff;
    bottom: 0;
    transition: width #{$list-transition};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    &.focusContainerOpen {
      height: calc(100% - #{$desktop-navbar-height});
      width: $desktop-list-width;
      min-width: 408px;
      /* overflow: hidden; */
    }

    &.focusContainerClose {
      height: calc(100% - #{$desktop-navbar-height});
      width: 0;
      /* padding-right: 0; */
    }
  }
}
